import explanations_img from '../images/information.png';
import chat_img from '../images/conversation.png';
import internal_data_img from '../images/company.png';
import user_friendly_img from '../images/users.png';
import data_img from '../images/data-driven.png';
import faster_img from '../images/clock.png';
import austin from '../images/austin.jpeg';
import josh from '../images/josh.jpeg';
import leland from '../images/leland.jpeg';

const Services = () => {
  return (
    <div id="features" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 font-bold">
            Key Features
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-6 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={faster_img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Better Decisions, No Guesswork
                </h2>
                <p className="text-md font-medium">
                  Our AI analyzes real data to deliver precise location
                  recommendations within minutes.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={data_img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Real Data
                </h2>
                <p className="text-md font-medium">
                  We utilize millions of data points to ensure your decisions
                  are backed by accurate, comprehensive insights.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={explanations_img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Explanations
                </h2>
                <p className="text-md font-medium">
                  We don't just provide recommendations; we explain them by
                  highlighting the top factors influencing our suggestions.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={user_friendly_img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  User Friendly
                </h2>
                <p className="text-md font-medium">
                  No technical knowledge required to integrate your business
                  with our easy-to-use platform.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={internal_data_img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Incorporate Internal Data (Coming Soon!)
                </h2>
                <p className="text-md font-medium">
                  You'll be able to incorporate your internal data seamlessly
                  into our system.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={chat_img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Chat with AI (Coming Soon!)
                </h2>
                <p className="text-md font-medium">
                  You'll be able to chat with AI to better understand the
                  recommendations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 font-bold">
            The Team
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-md p-4 group max-w-sm mx-auto">
              <div className="flex flex-col items-center text-center">
                <img
                  alt="card img"
                  className="w-20 h-20 object-cover rounded-t transition duration-500 ease-in-out"
                  src={josh}
                />
                <h2 className="font-semibold my-2 text-lg">Josh Karbi</h2>
                <p className="text-sm font-medium">
                  Software Engineer with experience at Tinder, Robinhood, and a
                  quant hedge fund.
                </p>
                <a
                  href="https://www.linkedin.com/in/josh-karbi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="hover:scale-105"
                    src="../images/linkedin.svg"
                    alt="LinkedIn Logo"
                  ></img>
                </a>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-md p-4 group max-w-sm mx-auto">
              <div className="flex flex-col items-center text-center">
                <img
                  alt="card img"
                  className="w-20 h-20 object-cover rounded-t transition duration-500 ease-in-out"
                  src={austin}
                />
                <h2 className="font-semibold my-2 text-lg">Austin Kranc</h2>
                <p className="text-sm font-medium">
                  Software Engineer with experience at Nvidia, AWS, and Shopify.
                  Geography content creator with 250k+ combined followers.
                </p>
                <a
                  href="https://www.linkedin.com/in/austinkranc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="hover:scale-105"
                    src="../images/linkedin.svg"
                    alt="LinkedIn Logo"
                  ></img>
                </a>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-md p-4 group max-w-sm mx-auto">
              <div className="flex flex-col items-center text-center">
                <img
                  alt="card img"
                  className="w-20 h-20 object-cover rounded-t transition duration-500 ease-in-out"
                  src={leland}
                />
                <h2 className="font-semibold my-2 text-lg">Leland Conn</h2>
                <p className="text-sm font-medium">
                  Software Engineer with experience at AWS. Built a CPG
                  business, and worked in B2B software.
                </p>
                <a
                  href="https://www.linkedin.com/in/leland-conn/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="hover:scale-105"
                    src="../images/linkedin.svg"
                    alt="LinkedIn Logo"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
