
const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-transparent-900 p-8 bg-transparent-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">

                        </div>
                    </div>

                   
                    <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                       
                    </div>

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                            
                            </div>
                        </div>
                    </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
