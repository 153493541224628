import { Icon, divIcon, Point, LatLngTuple } from "leaflet";
import React from "react";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";

import { RecommendedLocation } from "../../types/RecommendedLocation";
import ErrorPage from "../ErrorPage/ErrorPage";

import "./styles.css";
import { MAP_PIN_COLORS } from "../../constants/mapPinColors";

const createMapPinSVG = (color: string) => `
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="50" viewBox="0 0 24 24">
    <path 
      d="M12 2C8.13 2 5 5.13 5 9c0 3.45 6.82 12.56 6.82 12.56.24.28.57.44.93.44.36 0 .69-.16.93-.44C12.18 21.56 19 12.45 19 9c0-3.87-3.13-7-7-7zm0 10.5c-1.39 0-2.5-1.11-2.5-2.5S10.61 7.5 12 7.5s2.5 1.11 2.5 2.5-1.11 2.5-2.5 2.5z" 
      fill="${color}" 
      stroke="black" 
      stroke-width="1"/>
  </svg>
`;

interface Cluster {
  getChildCount: () => number;
}

const createClusterCustomIcon = (cluster: Cluster) => {
  return divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: new Point(33, 33, true),
  });
};

interface MapProps {
  recommendedLocations: RecommendedLocation[];
  center: LatLngTuple;
}

const Map: React.FC<MapProps> = ({ center, recommendedLocations }) => {
  if (recommendedLocations.length === 0) {
    return (
      <ErrorPage
        title="No Locations Available"
        message="There are no recommended locations to display."
      />
    );
  }

  return (
    <div className="map">
      <MapContainer center={center} zoom={12}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createClusterCustomIcon}
        >
          {recommendedLocations.map((location, index) => (
            <Marker
              key={location.zip_code}
              position={[location.latitude, location.longitude]}
              icon={
                new Icon({
                  iconUrl: `data:image/svg+xml;base64,${btoa(
                    createMapPinSVG(
                      MAP_PIN_COLORS[index % MAP_PIN_COLORS.length]
                    )
                  )}`,
                  iconSize: [38, 50],
                })
              }
            >
              <Popup>
                <div
                  style={{
                    backgroundColor: MAP_PIN_COLORS[index % MAP_PIN_COLORS.length],
                    color: 'black',
                    padding: '10px',
                    margin: 0,
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box'
                  }}
                >
                  <h3 style={{ fontWeight: 'bold', margin: 0 }}>
                    {location.city}, {location.state}
                  </h3>
                  <p style={{ margin: '5px 0' }}>
                    Zip code: {location.zip_code}
                  </p>
                  <p style={{ margin: '5px 0' }}>
                    Rank: {location.rank}
                  </p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default Map;
