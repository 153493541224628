import { useMemo, useState } from 'react';

import { RecommendedLocation } from '../types/RecommendedLocation';
import { GetRecommendedLocationsAPIResponse } from '../types/RecommendedLocations';

const useSubmitForm = () => {
  const [businessName, setBusinessName] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [businessDesiredOption, setBusinessDesiredOption] = useState('');
  const [customValue, setCustomValue] = useState(''); // New state for custom value
  const [businessRegion, setBusinessRegion] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [recommendedLocations, setRecommendedLocations] = useState<
    RecommendedLocation[]
  >([]);
  const [explanationBlurb, setExplanationBlurb] = useState('');
  const [error, setError] = useState<string | null>(null);

  const isFormValid = useMemo(
    () =>
      businessName.trim() !== '' &&
      businessDescription.trim() !== '' &&
      businessDesiredOption.trim() !== '' &&
      businessRegion.trim() !== '',
    [businessName, businessDescription, businessDesiredOption, businessRegion]
  );

  const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBusinessName(e.target.value);
  };

  const handleBusinessDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setBusinessDescription(e.target.value);
  };

  const handleBusinessDesiredOptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBusinessDesiredOption(e.target.value);
  };

  const handleCustomValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomValue(e.target.value);
  };

  const handleBusinessRegionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBusinessRegion(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null); // Clear previous errors

    if (process.env.MOCK_API_RESPONSES === 'true') {
      const mockResponse =
        await new Promise<GetRecommendedLocationsAPIResponse>(
          (resolve) =>
            setTimeout(
              () =>
                resolve({
                  top_decision_factors: [
                    'Median Household Income in 2019 Inflation Adjusted Dollars',
                    'People Earning $75,000 to $99,999',
                    'People Earning $100,000 to $124,999',
                    'People Earning $150,000 to $199,999',
                    'People Earning $200,000 or More',
                  ],
                  explanation_blurb:
                    "Leland's Ice Cream Shop should prioritize targeting areas with higher median household income, especially those with a greater proportion of individuals in higher income brackets.  Additionally, focusing on areas with larger family households, a higher proportion of college graduates, and those with a noticeable Spanish-speaking population can help identify areas with strong potential for ice cream sales.",
                  recommendations: [
                    {
                      zip_code: '35741',
                      rank: 1,
                      where_ai_score_normalized: 1.43,
                      where_ai_score_raw: 0.14250232558139536,
                      latitude: 34.7085096,
                      longitude: -86.4644934,
                      city: 'BROWNSBORO',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 181224,
                        'People Earning $75,000 to $99,999': 176,
                        'People Earning $100,000 to $124,999': 231,
                        'People Earning $150,000 to $199,999': 622,
                        'People Earning $200,000 or More': 1217,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '36830',
                      rank: 2,
                      where_ai_score_normalized: 1.42,
                      where_ai_score_raw: 0.1416360465116279,
                      latitude: 32.5840526,
                      longitude: -85.4464979,
                      city: 'AUBURN',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 122026,
                        'People Earning $75,000 to $99,999': 117,
                        'People Earning $100,000 to $124,999': 696,
                        'People Earning $150,000 to $199,999': 360,
                        'People Earning $200,000 or More': 669,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35213',
                      rank: 3,
                      where_ai_score_normalized: 1.4,
                      where_ai_score_raw: 0.1396232558139535,
                      latitude: 33.4946041,
                      longitude: -86.7638063,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 124849,
                        'People Earning $75,000 to $99,999': 272,
                        'People Earning $100,000 to $124,999': 415,
                        'People Earning $150,000 to $199,999': 243,
                        'People Earning $200,000 or More': 1151,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35223',
                      rank: 4,
                      where_ai_score_normalized: 1.31,
                      where_ai_score_raw: 0.130953488372093,
                      latitude: 33.4685015,
                      longitude: -86.6904595,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 175034,
                        'People Earning $75,000 to $99,999': 135,
                        'People Earning $100,000 to $124,999': 111,
                        'People Earning $150,000 to $199,999': 251,
                        'People Earning $200,000 or More': 1190,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35226',
                      rank: 5,
                      where_ai_score_normalized: 1.24,
                      where_ai_score_raw: 0.12408255813953488,
                      latitude: 33.4013795,
                      longitude: -86.8278599,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 138453,
                        'People Earning $75,000 to $99,999': 398,
                        'People Earning $100,000 to $124,999': 262,
                        'People Earning $150,000 to $199,999': 466,
                        'People Earning $200,000 or More': 539,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35223',
                      rank: 6,
                      where_ai_score_normalized: 1.24,
                      where_ai_score_raw: 0.12384651162790698,
                      latitude: 33.5031808,
                      longitude: -86.704922,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 139861,
                        'People Earning $75,000 to $99,999': 157,
                        'People Earning $100,000 to $124,999': 267,
                        'People Earning $150,000 to $199,999': 413,
                        'People Earning $200,000 or More': 651,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35223',
                      rank: 7,
                      where_ai_score_normalized: 1.24,
                      where_ai_score_raw: 0.12340697674418606,
                      latitude: 33.4869177,
                      longitude: -86.7636484,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 189625,
                        'People Earning $75,000 to $99,999': 201,
                        'People Earning $100,000 to $124,999': 44,
                        'People Earning $150,000 to $199,999': 131,
                        'People Earning $200,000 or More': 796,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35243',
                      rank: 8,
                      where_ai_score_normalized: 1.21,
                      where_ai_score_raw: 0.1211,
                      latitude: 33.4363969,
                      longitude: -86.7561972,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 175156,
                        'People Earning $75,000 to $99,999': 161,
                        'People Earning $100,000 to $124,999': 176,
                        'People Earning $150,000 to $199,999': 219,
                        'People Earning $200,000 or More': 901,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35242',
                      rank: 9,
                      where_ai_score_normalized: 1.21,
                      where_ai_score_raw: 0.12045,
                      latitude: 33.4007262,
                      longitude: -86.6503506,
                      city: 'BIRMINGHAM',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 137750,
                        'People Earning $75,000 to $99,999': 236,
                        'People Earning $100,000 to $124,999': 276,
                        'People Earning $150,000 to $199,999': 356,
                        'People Earning $200,000 or More': 1018,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                    {
                      zip_code: '35406',
                      rank: 10,
                      where_ai_score_normalized: 1.2,
                      where_ai_score_raw: 0.11972558139534885,
                      latitude: 33.2440377,
                      longitude: -87.5324292,
                      city: 'TUSCALOOSA',
                      state: 'AL',
                      top_decision_factor_values: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': 132568,
                        'People Earning $75,000 to $99,999': 128,
                        'People Earning $100,000 to $124,999': 211,
                        'People Earning $150,000 to $199,999': 385,
                        'People Earning $200,000 or More': 508,
                      },
                      top_decision_factor_percentiles: {
                        'Median Household Income in 2019 Inflation Adjusted Dollars': '99.9',
                        'People Earning $75,000 to $99,999': '99.8',
                        'People Earning $100,000 to $124,999': '77.7',
                        'People Earning $150,000 to $199,999': '99.9',
                        'People Earning $200,000 or More': '80.5',
                      },
                    },
                  ],
                }),
              2000
            ) // Simulate a 2-second delay
        );

      setExplanationBlurb(mockResponse.explanation_blurb ?? '');
      setRecommendedLocations(mockResponse.recommendations);
      setSubmitted(true);
      setLoading(false);
    } else {
      try {
        let endpoint = window.origin.includes('localhost')
          ? 'http://localhost:8000/api/v1/recommended_business_locations/'
          : `${window.origin}/api/v1/recommended_business_locations/`;
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            business_name: businessName,
            business_description: businessDescription,
            business_problem:
              businessDesiredOption === 'Custom'
                ? customValue
                : businessDesiredOption, // Include custom value if selected
            us_state_to_search_in: businessRegion,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data: GetRecommendedLocationsAPIResponse = await response.json();
        setExplanationBlurb(data.explanation_blurb ?? '');
        setRecommendedLocations(data.recommendations);
        setSubmitted(true);
      } catch (err) {
        setError('Failed to fetch recommendations. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    businessName,
    businessDescription,
    businessDesiredOption,
    customValue, // Return custom value state
    businessRegion,
    isLoading,
    isSubmitted,
    explanationBlurb,
    recommendedLocations,
    error,
    handleBusinessNameChange,
    handleBusinessDescriptionChange,
    handleBusinessDesiredOptionChange,
    handleCustomValueChange, // Return handler for custom value
    handleBusinessRegionChange,
    handleSubmit,
    isFormValid,
  };
};

export default useSubmitForm;
