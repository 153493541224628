const escapeCSVValue = (value) => {
  if (typeof value === 'string') {
    value = value.replace(/"/g, '""');
    // Wrap in double quotes if it contains commas, newlines, or double quotes
    if (value.search(/("|,|\n)/g) >= 0) {
      value = `"${value}"`;
    }
  }
  return value;
};

export const convertToCSV = (data) => {
  if (data.length === 0) return '';

  // Extract headers from the first item
  const allTopDecisionFactorKeys = data.flatMap((item) =>
    Object.keys(item.top_decision_factor_values)
  );
  const uniqueTopDecisionFactorKeys = [...new Set(allTopDecisionFactorKeys)];

  const headers = [
    'zip_code',
    'rank',
    'latitude',
    'longitude',
    'city',
    'state',
    ...uniqueTopDecisionFactorKeys,
  ];

  const escapedHeaders = headers.map((header) => escapeCSVValue(header));

  const rows = data.map((item) => {
    const topDecisionFactors = item.top_decision_factor_values;
    return [
      item.zip_code,
      item.rank,
      item.latitude,
      item.longitude,
      item.city,
      item.state,
      ...uniqueTopDecisionFactorKeys.map(
        (key) => topDecisionFactors[key] || ''
      ),
    ];
  });

  // Combine headers and rows into CSV string
  return [escapedHeaders.join(','), ...rows.map((row) => row.join(','))].join(
    '\n'
  );
};

export const downloadCSV = (csvData, filename) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
