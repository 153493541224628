import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import axios from 'axios';
import Notiflix from 'notiflix';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [messageSent, setMessageSent] = useState(false);
  const [sending, setSending] = useState(false); // State to manage sending status

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleContactSubmit = (e) => {
    e.preventDefault();
    setSending(true); // Set sending status to true

    const templateParams = {
      from_name: 'team@whereai.tech',
      message:
        message + ' From: ' + firstName + ' ' + lastName + ' (' + email + ')',
      to_name: 'Where AI Team',
      reply_to: email,
    };

    if (!firstName || !lastName || !email || !message) {
      Notiflix.Report.failure(
        'Failed',
        'Missing required fields, please try again.',
        'Okay'
      );
      setSending(false); // Reset sending status
      return;
    }

    emailjs
      .send(
        'service_4mkjiku',
        'template_9hxkuj2',
        templateParams,
        'HJPDXYADmH_WlAcWh'
      )
      .then((response) => {
        setMessageSent(true);
        setSending(false); // Reset sending status
        clearInput();
        Notiflix.Report.success('Success', 'Email sent successfully!', 'Okay');
      })
      .catch((err) => {
        console.error('Failed to send email:', err);
        setSending(false); // Reset sending status
        Notiflix.Report.failure(
          'Failed',
          'Failed to send email. Please try again.',
          'Okay'
        );
      });
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex items-center justify-center min-h-screen py-12 lg:py-24"
        data-aos="zoom-in"
      >
        <div className="w-full max-w-lg">
          <form
            onSubmit={handleContactSubmit}
            className="bg-white p-8 rounded-2xl shadow-2xl mx-auto"
          >
            <h1 className="font-bold text-center text-blue-900 text-4xl">
              Contact Us
            </h1>
            <div className="grid grid-cols-1 gap-5 mt-5">
              <div className="flex gap-5">
                <input
                  name="first_name"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="First Name*"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onKeyUp={clearErrors}
                />
                <input
                  name="last_name"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Last Name*"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onKeyUp={clearErrors}
                />
              </div>
              {errors && (
                <p className="text-red-500 text-sm">
                  {errors.first_name || errors.last_name}
                </p>
              )}
              <div>
                <input
                  name="email"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={clearErrors}
                />
                {errors && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div>
                <textarea
                  name="message"
                  placeholder="Message*"
                  className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={clearErrors}
                ></textarea>
                {errors && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
            </div>
            <div className="my-4">
              <button
                type="submit"
                id="submitBtn"
                className={`text-sm font-bold tracking-wide p-3 rounded-lg w-full focus:outline-none focus:shadow-outline ${
                  sending
                    ? 'bg-gray-500'
                    : 'bg-gray-500 hover:bg-blue-900 text-gray-100'
                }`}
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
          {messageSent && !sending}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
