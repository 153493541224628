import { LatLngTuple } from 'leaflet';
import React, { useCallback, useState } from 'react';
import { RecommendedLocation } from '../../types/RecommendedLocation';
import { convertToCSV, downloadCSV } from '../../utils/csvUtils';
import Map from '../Map/Map';
import RecommendedLocations from '../RecommendedLocations/RecommendedLocations';

import './styles.css';

interface RecommendationReportProps {
  explanationBlurb: string;
  recommendedLocations: RecommendedLocation[];
  businessQuestion: string;
  businessName: string;
}

const RecommendationReport: React.FC<RecommendationReportProps> = ({
  explanationBlurb,
  recommendedLocations,
  businessQuestion,
  businessName,
}) => {
  const [mapCenter, setMapCenter] = useState<LatLngTuple>([
    recommendedLocations[0]?.latitude || 0,
    recommendedLocations[0]?.longitude || 0,
  ]);

  const handleCardClick = useCallback((latitude: number, longitude: number) => {
    setMapCenter([latitude, longitude]);
  }, []);

  const handleDownload = () => {
    const csvData = convertToCSV(recommendedLocations);
    downloadCSV(csvData, 'recommended_locations.csv');
  };

  return (
    <div className="recommendation-report-container">
      <div className="business-info-container">
        <div className="business-info-card">
          <h2 className="business-name">{businessName}</h2>
          <p className="business-question">{businessQuestion}</p>
        </div>
        <button className="download-button" onClick={handleDownload}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="download-icon"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="7 10 12 15 17 10" />
            <line x1="12" y1="15" x2="12" y2="3" />
          </svg>
        </button>
      </div>
      <div className="recommendation-report-content">
        <RecommendedLocations
          recommendedLocations={recommendedLocations}
          onCardClick={handleCardClick}
          explanationBlurb={explanationBlurb}
        />
        <Map
          key={`${mapCenter[0]}-${mapCenter[1]}`}
          center={mapCenter}
          recommendedLocations={recommendedLocations}
        />
      </div>
    </div>
  );
};

export default RecommendationReport;
