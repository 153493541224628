import React from 'react';

import NavBar from '../components/Navbar/NavBar'; // Ensure this path is correct
import Footer from '../components/Footer'; // Import the Footer component
import './ExampleQuestionsPage.css'; // Import the CSS file for styling

const parseText = (text) => {
  const parts = text.split(/(<b>.*?<\/b>)/g); // Split the text by <b> tags
  return parts.map((part, index) => {
    if (part.startsWith('<b>') && part.endsWith('</b>')) {
      const content = part.replace(/<\/?b>/g, ''); // Remove <b> tags
      return <b key={index}>{content}</b>;
    }
    return <React.Fragment key={index}>{part}</React.Fragment>;
  });
};

const SafeHTMLRenderer = ({ text }) => {
  return <div>{parseText(text)}</div>;
};

const ExampleQuestionsPage = () => {
  const questions = {
    'Product Launch': {
      emoji: '🚀',
      items: [
        "Where should we launch our new product?",
        "Where can we conduct successful launch events to attract attention?",
        "Where are our target customers most likely to be early adopters?",
      ]
    },
    'Advertising': {
      emoji: '📈',
      items: [
        "What locations would be best to focus our ad spend?",
        "Where is our target audience most concentrated?",
        "Which locations have the highest potential for customer engagement?",
      ]
    },
    'Market Research': {
      emoji: '📊',
      items: [
        "Where should we conduct customer research to get the most relevant insights?",
        "Where can we find a certain demographic to run a focus group?",
        "Where are consumer preferences trending towards our product category?",
      ]
    },
    'Expansion': {
      emoji: '🌍',
      items: [
        "Where should we expand our business to reach a new audience?",
        "Where is the best location to open a new store?",
        "Where can we find emerging markets to invest in?",
      ]
    }
  };

  return (
    <>
      <NavBar /> {/* Include the Navbar */}
      <div className="example-questions-container">
        <div className="hero-section" data-aos="fade-up">
          <h1 className="title">Example Questions</h1>
        </div>
        <div className="content-section">
          {Object.entries(questions).map(([industry, { emoji, items }]) => (
            <div key={industry} className="industry-section" data-aos="fade-up">
              <div className="industry-header" data-aos="fade-right">
                <span className="industry-emoji">{emoji}</span>
                <h2 className="industry-title">{industry}</h2>
              </div>
              <ul className="question-list" data-aos="fade-left">
                {items.map((question, index) => (
                  <li key={index} className="question-item">

                    <SafeHTMLRenderer text={question} />

                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <Footer /> {/* Include the Footer */}
    </>
  );
};

export default ExampleQuestionsPage;
