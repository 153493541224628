import React from "react";
import "../../styles/styles.css"; // Importing the shared stylesheet

interface BusinessDescriptionProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const BusinessDescriptionInput: React.FC<BusinessDescriptionProps> = ({
  value,
  onChange,
}) => {
  return (
    <div className="form-group">
      <textarea
        id="businessDescription"
        value={value}
        onChange={onChange}
        required
        placeholder="Describe your business/product/service"
      />
    </div>
  );
};

export default BusinessDescriptionInput;