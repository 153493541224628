import React, { useMemo } from 'react';
import useSubmitForm from '../../hooks/useSubmitForm';
import BusinessNameInput from '../BusinessNameInput/BusinessNameInput';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import BusinessDescriptionInput from '../BusinessDescriptionInput/BusinessDescriptionInput';
import BusinessDesiredOptionInput from '../BusinessDesiredOptionInput/BusinessDesiredOptionInput';
import BusinessRegionInput from '../BusinessRegionInput/BusinessRegionInput';
import RecommendationReport from '../RecommendationReport/RecommendationReport';
import '../../styles/styles.css'; // Importing the shared stylesheet
import NavBar from '../Navbar/NavBar';
import Footer from '../Footer';

const BusinessForm: React.FC = () => {
  const {
    businessName,
    businessDescription,
    businessDesiredOption,
    businessRegion,
    isLoading,
    isSubmitted,
    explanationBlurb,
    recommendedLocations,
    customValue,
    handleBusinessNameChange,
    handleBusinessDescriptionChange,
    handleBusinessDesiredOptionChange,
    handleCustomValueChange,
    handleBusinessRegionChange,
    handleSubmit,
    isFormValid,
  } = useSubmitForm();

  const isFormValidWithCustom = useMemo(() => {
    if (businessDesiredOption === 'Custom' && !customValue.trim()) {
      return false;
    }
    return isFormValid;
  }, [businessDesiredOption, customValue, isFormValid]);

  const renderContent = useMemo((): JSX.Element => {
    switch (true) {
      case isLoading:
        return <LoadingScreen />;
      case isSubmitted:
        return (
          <div className="mx-auto my-8 px-4 lg:px-20">
            <RecommendationReport
              recommendedLocations={recommendedLocations}
              explanationBlurb={explanationBlurb}
              businessName={businessName}
              businessQuestion={
                businessDesiredOption === 'Custom'
                  ? customValue
                  : businessDesiredOption
              }
            />
          </div>
        );
      default:
        return (
          <div className="container mx-auto my-8 px-4 lg:px-20">
            <form
              onSubmit={handleSubmit}
              className="form-container bg-white p-8 rounded-2xl shadow-2xl"
            >
              <div className="text-center mb-6">
                <h1 className="font-bold text-blue-900 text-4xl">Demo</h1>
                <p className="text-gray-700 text-lg mt-4">
                  Fill out the form below to receive personalized
                  recommendations for your business.
                </p>
              </div>
              <div className="space-y-6">
                <div className="form-group">
                  <label htmlFor="businessName">
                    What is your business name?
                  </label>
                  <BusinessNameInput
                    value={businessName}
                    onChange={handleBusinessNameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="businessDescription">
                    What is your business/product/service?{' '}
                  </label>
                  <BusinessDescriptionInput
                    value={businessDescription}
                    onChange={handleBusinessDescriptionChange}
                  />
                  <label htmlFor="businessDescription" className="text-sm">
                    Describe your target audience for the best results
                  </label>
                </div>
                <div className="form-group">
                  <BusinessDesiredOptionInput
                    value={businessDesiredOption}
                    customValue={customValue}
                    onChange={handleBusinessDesiredOptionChange}
                    onCustomValueChange={handleCustomValueChange}
                  />
                </div>
                <div className="form-group">
                  <BusinessRegionInput
                    value={businessRegion}
                    onChange={handleBusinessRegionChange}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-3 rounded-lg mt-5 focus:outline-none focus:shadow-outline"
                disabled={!isFormValidWithCustom}
              >
                Get Location Recommendations
              </button>
            </form>
          </div>
        );
    }
  }, [
    isLoading,
    isSubmitted,
    explanationBlurb,
    recommendedLocations,
    businessName,
    businessDescription,
    businessDesiredOption,
    businessRegion,
    handleBusinessNameChange,
    handleBusinessDescriptionChange,
    handleBusinessDesiredOptionChange,
    handleBusinessRegionChange,
    handleCustomValueChange,
    handleSubmit,
    isFormValidWithCustom,
    customValue,
  ]);

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <main className="flex-grow">
        <div className="businessForm">{renderContent}</div>
      </main>
      <Footer />
    </div>
  );
};

export default BusinessForm;
