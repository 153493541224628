import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'; // Import Link for internal routing

const NavLinks = ({ onLinkClick }) => {
  return (
    <>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#about" onClick={onLinkClick}>
        About
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#features" onClick={onLinkClick}>
        Features
      </HashLink>
      <Link className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/examples" onClick={onLinkClick}>
        Examples
      </Link>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/contact#contact" onClick={onLinkClick}>
        Contact Us
      </HashLink>
      <HashLink 
        className="text-white hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" 
        smooth 
        to="/get-demo#demo" 
        onClick={onLinkClick}
        style={{ backgroundColor: '#1e3a8a' }}
      >
        Demo
      </HashLink>
    </>
  );
}

export default NavLinks;
