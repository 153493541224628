import React from "react";

import "./styles.css";

interface ErrorPageProps {
  title: string;
  message: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, message }) => {
  return (
    <div className="error-container">
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  );
};

export default ErrorPage;
