export const MAP_PIN_COLORS = [
  "#A0D6E9", // Pastel Turquoise
  "#D3E84B", // Pastel Green Yellow
  "#F5A6B2", // Pastel Hot Pink
  "#F6E58D", // Pastel Royal Yellow
  "#A2D9A2", // Pastel Green
  "#D5A6F1", // Pastel Purple
  "#F5B27E", // Pastel Orange
  "#D3D3D3", // Pastel Black (light grey)
  "#9AB3E8", // Pastel Royal Blue
  "#D8B4A6", // Pastel Brown
];
