import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import BusinessForm from '../components/BusinessForm/BusinessForm';

interface Errors {
  products?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  message?: string;
}

const DemoProduct: React.FC = () => {
  const [demoPassword, setDemoPassword] = useState<string>('');
  const [demoProducts, setDemoProducts] = useState<string[]>([]);
  const [errors, setErrors] = useState<Errors>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const checked = e.target.checked;
    setErrors((prevErrors) => ({ ...prevErrors, products: undefined }));
    if (checked) {
      setDemoProducts([...demoProducts, value]);
    } else {
      setDemoProducts(demoProducts.filter((e) => e !== value));
    }
  };

  const clearErrors = () => {
    setErrors({});
  };

  const clearInput = () => {
    setDemoPassword('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (demoPassword === 'demo2024') {
      setFormSubmitted(true);
    } else {
      setErrors({ ...errors, products: 'Invalid demo password' });
    }
    clearInput();
  };

  if (formSubmitted) {
    return <BusinessForm />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <main className="flex-grow">
        <div
          id="demo"
          className="flex justify-center items-center mt-8 w-full h-full py-12 lg:py-24"
        >
          <div
            className="container mx-auto my-8 px-4 lg:px-20"
            data-aos="zoom-in"
          >
            <form onSubmit={handleSubmit} id="demoProductForm">
              <div className="w-full bg-white p-8 my-4 md:px-12 lg:px-20 mr-auto rounded-2xl shadow-2xl flex flex-col items-center">
                <div className="flex">
                  <h1 className="font-bold text-center text-blue-900 text-4xl">
                    Demo
                  </h1>
                </div>

                {errors.products && (
                  <p className="text-red-500 text-sm">{errors.products}</p>
                )}
                <div className="grid grid-cols-1 gap-5 md:grid-cols-1 mt-5 w-full">
                  <div className="flex flex-col items-center w-full">
                    <input
                      name="demo_password"
                      className="w-3/4 max-w-md bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="password"
                      placeholder="Password"
                      value={demoPassword}
                      onChange={(e) => setDemoPassword(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors.first_name && (
                      <p className="text-red-500 text-sm">
                        {errors.first_name}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col items-center w-full">
                    <button
                      id="submitBtn"
                      type="submit"
                      className="bg-blue-500 text-white p-3 rounded-lg mt-5 focus:outline-none focus:shadow-outline w-1/2 max-w-xs"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="flex flex-col items-center w-full">
                    <p className="text-sm text-gray-600 mt-4 text-center">
                      Don’t have a demo password?
                      <br />
                      <span>
                        <Link
                          to="/contact#contact"
                          className="text-blue-500 hover:underline"
                        >
                          Contact us
                        </Link>{' '}
                        or{' '}
                        <a
                          href="https://calendly.com/team-whereai/30min"
                          className="text-blue-500 hover:underline"
                          target="_blank"
                        >
                          schedule a call
                        </a>{' '}
                        to arrange a demo.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DemoProduct;
