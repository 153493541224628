export const capitalizeFirstLetter = (str: string): string => {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceUnderscoresWithSpaces = (str: string): string => {
  return str.replace(/_/g, ' ');
};

export const splitOnCapitalizedLetters = (str: string): string => {
  return str.replace(/([A-Z])/g, ' $1').trim();
}