import React from 'react';
import RecommendedLocations from '../components/RecommendedLocations/RecommendedLocations';
import { RecommendedLocation } from '../types/RecommendedLocation';

const mockRecommendedLocations: RecommendedLocation[] = [
  // Add mock data for testing
  {
    city: 'Sample City',
    state: 'SC',
    zip_code: '12345',
    latitude: 34.0522,
    longitude: -118.2437,
    rank: 1,
    top_decision_factor_values: {
      population: 'High',
      income: 'Medium',
    },
  },
  // Add more mock data as needed
];

const RecommendedLocationsPage: React.FC = () => {
  const handleCardClick = (latitude: number, longitude: number) => {
    // Handle card click if needed
    console.log('Card clicked:', latitude, longitude);
  };

  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh', padding: '20px' }}>
      <RecommendedLocations
        recommendedLocations={mockRecommendedLocations}
        onCardClick={handleCardClick}
      />
    </div>
  );
};

export default RecommendedLocationsPage;
