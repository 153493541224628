import React, { useState } from "react";
import "../../styles/styles.css"; // Importing the shared stylesheet

interface BusinessDesiredOptionProps {
  value: string;
  customValue: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onCustomValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const BusinessDesiredOptionInput: React.FC<BusinessDesiredOptionProps> = ({
  value,
  customValue,
  onChange,
  onCustomValueChange,
}) => {
  const [isCustom, setIsCustom] = useState(value === "Custom");

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setIsCustom(selectedValue === "Custom");
    onChange(e);
  };

  return (
    <div className="form-group">
      <label htmlFor="businessDesiredOption">What do you need us to solve?</label>
      <select
        id="businessDesiredOption"
        value={value}
        onChange={handleSelectChange}
        required
      >
        <option value="" disabled>
          Choose your need
        </option>
        <option value="Where should my business launch its new product?">Where should my business launch its new product?</option>
        <option value="What locations should my business target its ads?">What locations should my business target its ads?</option>
        <option value="Where should my business open a new store?">Where should my business open a new store?</option>
        <option value="Where are the best areas to find skilled talent for my business?">Where are the best areas to find skilled talent for my business?</option>
        <option value="Where should we host a pop-up to engage with the most customers?">Where should we host a pop-up to engage with the most customers?</option>
        <option value="Custom">Custom (Write it yourself)</option>
      </select>
      {isCustom && (
        <input
          type="text"
          className="text-field"
          placeholder="Describe your custom need"
          value={customValue}
          onChange={onCustomValueChange}
          required
        />
      )}
    </div>
  );
};

export default BusinessDesiredOptionInput;
