import React, { useState } from 'react';
import { MAP_PIN_COLORS } from '../../constants/mapPinColors';
import { RecommendedLocation } from '../../types/RecommendedLocation';
import {
  capitalizeFirstLetter,
  splitOnCapitalizedLetters,
} from '../../utils/stringUtils';
import './styles.css';

export interface RecommendedLocationsProps {
  recommendedLocations: RecommendedLocation[];
  onCardClick: (latitude: number, longitude: number) => void;
  explanationBlurb?: string; // Prop for explanation blurb
}

const RecommendedLocations: React.FC<RecommendedLocationsProps> = ({
  recommendedLocations,
  onCardClick,
  explanationBlurb,
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [isExplanationExpanded, setIsExplanationExpanded] = useState<boolean>(false); // State for explanation expansion
  const [hoveringIndex, setHoveringIndex] = useState<number | null>(null); // Track hovered card

  const handleCardClick = (
    index: number,
    latitude: number,
    longitude: number
  ) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    onCardClick(latitude, longitude);
  };

  const handleCardMouseEnter = (index: number) => {
    setHoveringIndex(index);
  };

  const handleExplanationClick = () => {
    setIsExplanationExpanded(!isExplanationExpanded);
  };

  const handleCardMouseLeave = () => {
    setHoveringIndex(null);
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '2em',
    fontWeight: 'bold',
    color: '#1e3a8a',
    textAlign: 'center',
  };

  return (
    <div className="recommended-locations">
      <div className="top-of-recommended-locations">
        <h2 style={titleStyle}>Recommended Locations</h2>
        {explanationBlurb && (
          <div className="explanation-container">
            <div
              className="explanation-header"
              onClick={handleExplanationClick} // Toggle explanation on click
            >
              <p className="overview-title">
                📊 Understand This Analysis
              </p>
            </div>
            {isExplanationExpanded && (
              <div className="explanation-blurb">
                <p className="explanation-text">{explanationBlurb}</p>
              </div>
            )}
          </div>
        )}
      </div>

      {recommendedLocations.map((location, index) => (
        <div
          key={index}
          className={`location-card ${
            expandedIndex === index ? 'expanded' : ''
          }`}
          style={{
            backgroundColor: MAP_PIN_COLORS[index % MAP_PIN_COLORS.length],
          }}
          onClick={() =>
            handleCardClick(index, location.latitude, location.longitude)
          }
          onMouseEnter={() => handleCardMouseEnter(index)}
          onMouseLeave={handleCardMouseLeave}
        >
          <div className="rank-container">
            <div className="rank">Rank: {location.rank}</div>
          </div>
          <div className="city-state">
            <strong>{location.city}</strong>, <strong>{location.state}</strong>
          </div>
          <div
            className={`address ${
              expandedIndex !== index && hoveringIndex === index ? 'expanded' : ''
            }`}
          >
            {expandedIndex === index
              ? `Zip Code: ${location.zip_code}`
              : !expandedIndex && hoveringIndex === index
              ? 'Click to Expand'
              : `Zip Code: ${location.zip_code}`}
          </div>

          {expandedIndex === index && (
            <div className="details">
              <div className="top-decision-factors-explainer">
                These were the highest contributing data points to the score:
              </div>
              <div className="decision-factors-container">
                {Object.keys(location.top_decision_factor_values).map((key) => (
                  <div key={key} className="decision-factor" 
                  style={{
                    border: `2px solid ${ MAP_PIN_COLORS[index % MAP_PIN_COLORS.length]}`, // Apply border color dynamically
                  }}>
                    <div className="decision-factor-title">
                      {capitalizeFirstLetter(splitOnCapitalizedLetters(key))}:
                    </div>
                    <div className="decision-factor-details">
                      <div className="decision-factor-value">
                        {location.top_decision_factor_values[key].toString() === '0'
                          ? 'N/A'
                          : location.top_decision_factor_values[key]}
                      </div>
                      <div className="percentile-value">
                        Percentile: {location.top_decision_factor_percentiles[key]}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="disclaimer">
                Data may be an approximation
              </div>
            </div>
          )}

          {/* Arrow Icon */}
          <div
            className={`arrow-icon ${
              expandedIndex === index ? 'expanded' : ''
            }`}
            onClick={() => handleCardClick(index, location.latitude, location.longitude)}
          >
            {expandedIndex === index ? '▼' : '▲'}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecommendedLocations;
