import React from "react";
import "./styles.css";

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-container">
      <div className="loading-icon"></div>
      <div className="loading-text">
        <div className="loading-main-text">Generating recommendation...</div>
        <div className="loading-subtext">Please allow up to 3 minutes.</div>
      </div>
    </div>
  );
};

export default LoadingScreen;
